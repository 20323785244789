// Radius
:root {
    --border-radius-none: 0px;
    --border-radius-sm: 0.125rem; /* 2px */
    --border-radius-base: 0.25rem; /* 4px */
    --border-radius-md: 0.375rem; /* 6px */
    --border-radius-lg: 0.5rem; /* 8px */
    --border-radius-xl: 0.75rem; /* 12px */
    --border-radius-2xl: 1rem; /* 16px */
    --border-radius-3xl: 1.5rem; /* 24px */
    --border-radius-full: 9999px;
}

// Width
:root {
    --border-width-none: 0px;
    --border-width-base: 1px;
    --border-width-sm: 2px;
    --border-width-md: 4px;
    --border-width-lg: 8px;
}
