.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: var(--max-width-card);

    text-align: center;
}

.logotype {
    margin-bottom: 1em;
}

.title {
    margin-bottom: 0.25em;

    font-weight: var(--font-base-weight-bold);
    font-size: 2.25em;

    strong {
        color: var(--color-brand-red);
    }
}

.subtitle {
    margin-bottom: 2.5em;
}

.image {
    margin-bottom: 2em;
}

.updateButton {
    margin-bottom: 0.25em;
}

.versionDetails {
    margin-bottom: 0.75em;

    color: var(--color-version-details);
    font-size: 0.75em;
}

.legal {
    color: var(--color-text-subtext);
    font-size: 0.75em;
}
