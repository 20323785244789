.container {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 1.5em 0;

    &.inline {
        width: initial;
    }

    span,
    button {
        margin: 0 1em;

        font-size: 0.75em;
        text-align: center;
    }

    button {
        max-width: 11em;
    }
}
