:root {
    /* Colors */

    --color-white: hsl(0, 0%, 100%);
    --color-white-translucent: hsla(0, 0%, 100%, 0.3);
    --color-grey-100: hsl(180, 11.1%, 96.5%);
    --color-grey-200: hsl(0, 7%, 90%);
    --color-grey-300: hsl(180, 3.4%, 82.9%);
    --color-grey-700: hsl(0, 0%, 50.2%);
    --color-grey-700-translucent: hsla(0, 0%, 50.2%, 0.2);
    --color-grey-800: hsl(0, 0%, 25%);
    --color-grey-900: hsl(225, 5.9%, 13.3%);
    --color-grey-900-translucent-half: hsla(225, 5.9%, 13.3%, 0.5);
    --color-grey-900-translucent: hsla(225, 5.9%, 13.3%, 0.8);
    --color-brand-red-tint: hsl(5.5, 78.6%, 94.5%);
    --color-brand-red-tint-translucent: hsla(5.5, 78.6%, 94.5%, 0.2);
    --color-brand-red: hsl(4.6, 81.2%, 56.3%);
    --color-brand-red-shade: hsl(3.7, 70.9%, 49.8%);
    --color-brand-blue: hsl(218.4, 79.9%, 55.1%);
    --color-brand-blue-shade: hsl(218.5, 80%, 45.1%);
    --color-brand-green-tint: hsl(136, 40.2%, 94.1%);
    --color-brand-green: hsl(136, 40.2%, 54.1%);
    --color-brand-green-shade: hsl(135.8, 40.4%, 44.1%);
    --color-brand-gold: hsl(45, 96%, 50%);
    --color-brand-gold-shade: hsl(45, 96.1%, 40%);

    /* Colors: Typography */

    --color-text-base: var(--color-grey-900);
    --color-text-subtext: var(--color-grey-700);
    --color-text-watermark: var(--color-grey-300);

    /* Colors: Buttons */

    --color-button-text: var(--color-brand-blue);
    --color-button-text-muted: var(--color-grey-700);
    --color-button-primary-text: var(--color-white);
    --color-button-primary-background: var(--color-brand-red);
    --color-button-primary-hover-text: var(--color-white);
    --color-button-primary-hover-background: var(--color-brand-red-shade);
    --color-button-primary-disabled-text: var(--color-text-base);
    --color-button-primary-disabled-background: var(--color-grey-300);
    --color-button-secondary-text: var(--color-white);
    --color-button-secondary-background: var(--color-brand-blue);
    --color-button-secondary-hover-text: var(--color-white);
    --color-button-secondary-hover-background: var(--color-brand-blue-shade);
    --color-button-secondary-disabled-text: var(--color-white);
    --color-button-secondary-disabled-background: var(--color-grey-300);
    --color-button-secondary-outline-text: var(--color-brand-blue);
    --color-button-secondary-outline-color: var(--color-brand-blue);
    --color-button-secondary-outline-background: transparent;
    --color-button-secondary-outline-hover-text: var(--color-white);
    --color-button-secondary-outline-hover-background: var(--color-brand-blue);
    --color-button-secondary-outline-disabled-text: var(--color-grey-300);
    --color-button-secondary-outline-disabled-color: var(--color-grey-300);
    --color-button-secondary-outline-disabled-background: transparent;
    --color-button-secondary-white-text: var(--color-text-base);
    --color-button-secondary-white-background: var(--color-white);
    --color-button-secondary-white-hover-text: var(--color-text-base);
    --color-button-secondary-white-hover-background: var(--color-grey-200);
    --color-button-secondary-white-disabled-text: var(--color-text-base);
    --color-button-secondary-white-disabled-background: var(--color-grey-700);
    --color-button-secondary-grey-text: var(--color-text-base);
    --color-button-secondary-grey-background: var(--color-grey-100);
    --color-button-secondary-grey-hover-text: var(--color-text-base);
    --color-button-secondary-grey-hover-background: var(--color-grey-300);
    --color-button-secondary-grey-disabled-text: var(--color-text-base);
    --color-button-secondary-grey-disabled-background: var(--color-grey-700);
    --color-button-secondary-dark-grey-text: var(--color-white);
    --color-button-secondary-dark-grey-background: var(--color-grey-700);
    --color-button-secondary-dark-grey-hover-text: var(--color-white);
    --color-button-secondary-dark-grey-hover-background: var(--color-grey-900);
    --color-button-secondary-dark-grey-disabled-text: var(--color-white);
    --color-button-secondary-dark-grey-disabled-background: var(--color-text-base);
    --color-button-gold-text: var(--color-text-base);
    --color-button-gold-background: var(--color-brand-gold);
    --color-button-gold-hover-text: var(--color-text-base);
    --color-button-gold-hover-background: var(--color-brand-gold-shade);
    --color-button-gold-disabled-text: var(--color-text-base);
    --color-button-gold-disabled-background: var(--color-grey-300);
    --color-button-black-text: var(--color-white);
    --color-button-black-background: var(--color-text-base);
    --color-button-black-hover-text: var(--color-grey-200);
    --color-button-black-hover-background: var(--color-text-base);
    --color-button-black-disabled-text: var(--color-grey-700);
    --color-button-black-disabled-background: var(--color-text-base);
    --color-button-danger-text: var(--color-brand-red);
    --color-button-danger-color: var(--color-brand-red);
    --color-button-danger-background: transparent;
    --color-button-danger-hover-text: var(--color-white);
    --color-button-danger-hover-background: var(--color-brand-red);
    --color-button-danger-disabled-text: var(--color-grey-300);
    --color-button-danger-disabled-color: var(--color-grey-300);
    --color-button-danger-disabled-background: transparent;
    --color-button-success-text: var(--color-white);
    --color-button-success-background: var(--color-brand-green);
    --color-button-success-hover-text: var(--color-white);
    --color-button-success-hover-background: var(--color-brand-green-shade);
    --color-button-success-disabled-text: var(--color-text-base);
    --color-button-success-disabled-background: var(--color-grey-300);
    --color-button-stacked-border: var(--color-grey-200);
    --color-button-stacked-control: var(--color-grey-300);
    --color-button-stacked-hover-background: var(--color-grey-100);
    --color-button-close-text: var(--color-text-base);
    --color-button-close-background: var(--color-grey-100);
    --color-button-close-hover-text: var(--color-text-base);
    --color-button-close-hover-background: var(--color-grey-300);
    --color-button-close-inverse-text: var(--color-white);
    --color-button-close-inverse-background: transparent;
    --color-button-close-inverse-hover-text: var(--color-text-base);
    --color-button-close-inverse-hover-background: var(--color-grey-300);
    --color-button-header-text: var(--color-text-base);
    --color-button-header-background: var(--color-white);
    --color-button-header-hover-text: var(--color-text-base);
    --color-button-header-hover-background: var(--color-white);

    /* Colors: Inputs */

    --color-input-text: var(--color-text-base);
    --color-input-label-text: var(--color-text-subtext);
    --color-input-error-text: var(--color-brand-red);
    --color-input-placeholder: var(--color-grey-300);
    --color-input-background: var(--color-grey-100);
    --color-input-border: var(--color-grey-300);
    --color-input-border-focus: var(--color-brand-blue);
    --color-input-border-error: var(--color-brand-red);
    --color-input-toggle-unchecked: var(--color-grey-300);

    /* Colors: Borders */

    --color-border-base: var(--color-grey-300);
    --color-border-light: var(--color-grey-200);
    --color-border-dark: var(--color-grey-700);
    --color-border-header: var(--color-border-light);
    --color-border-drawer: var(--color-border-base);
    --color-border-menu-item: var(--color-border-base);
    --color-border-menu-item-hover: var(--color-border-dark);
    --color-border-hr: var(--color-border-base);
    --color-border-deal-rules: var(--color-border-light);
    --color-border-blockquote: var(--color-border-base);

    /* Colors: Pages */

    --color-page-background-grey: var(--color-grey-100);

    /* Colors: Components */

    --color-navigation-background: var(--color-white);
    --color-overlay: var(--color-grey-900-translucent);
    --color-overlay-light: var(--color-white-translucent);
    --color-modal-backdrop: var(--color-grey-900-translucent);
    --color-accordion-indicator: var(--color-grey-300);
    --color-image-frame-background: var(--color-grey-200);
    --color-link-indicator: var(--color-grey-300);
    --color-version-details: var(--color-grey-300);
    --color-instructions-box: var(--color-grey-100);
    --color-step-incomplete: var(--color-grey-300);
    --color-contest-card-inactive: var(--color-grey-200);
    --color-switch-foreground: var(--color-white);
    --color-switch-foreground-text: var(--color-text-base);
    --color-switch-background: var(--color-grey-200);
    --color-switch-background-text: var(--color-text-subtext);
    --color-switch-background-dark: var(--color-grey-300);
    --color-switch-background-checked: var(--color-text-base);
}

:root {
    /* Typography */

    --font-base-family: "Mulish Variable", sans-serif;
    --font-base-weight: 500;
    --font-base-weight-bold: 800;
    --font-space-character-width: 0.5ch;
}

:root {
    /* Shadows */

    --shadow-base: 0px 3px 6px hsla(0, 0%, 0%, 0.16);
    --shadow-base-depressed: 0px 1.5px 3px hsla(0, 0%, 0%, 0.16);
    --shadow-panel: 0px 0px 6px hsla(0, 0%, 0%, 0.16);
    --shadow-panel-bottom: 0px -3px 6px hsla(0, 0%, 0%, 0.16);
    --shadow-panel-top: 0px 3px 6px hsla(0, 0%, 0%, 0.16);
    --shadow-inset: 0px 0px 3px 0px hsla(0, 0%, 0%, 0.16) inset;
    --shadow-button: var(--shadow-base);
    --shadow-button-depressed: var(--shadow-base-depressed);
    --shadow-slide-button: inset 0px 1px 6px var(--color-grey-700-translucent);
    --shadow-slide-button-handle: 0px 0px 3px hsla(0, 0%, 0%, 0.16);
    --shadow-slide-button-primary: inset 0px 1px 6px var(--color-brand-red-tint-translucent);
    --shadow-card: var(--shadow-base);
    --shadow-card-depressed: var(--shadow-base-depressed);
    --shadow-pill: 0px 0px 6px hsla(0, 0%, 0%, 0.08);
    --shadow-text: 0px 3px 0px hsla(0, 0%, 0%, 0.16);
}

:root {
    /* Borders */

    --border-width-base: 1px;
    --border-width-blockquote: 0.5em;
    --border-width-viewfinder: 2px;
    --border-radius-auth-home: 2.5rem;
    --border-radius-panel: 0.5rem;
    --border-radius-field: 0.25rem;
    --border-radius-button: 0.25rem;
    --border-radius-badge: 0.25rem;
    --border-radius-slide-button: 2.5rem;
    --border-radius-card: 0.5rem;
    --border-radius-modal: 0.5rem;
    --border-radius-tag: 0.25rem;
    --border-radius-name-badge: 0.25rem;
    --border-radius-menu-item: 0.25rem;
    --border-radius-reward: 0.25rem;
    --border-radius-tab: 0.25rem;
    --border-radius-cover-image: 0.5rem;
    --border-radius-viewfinder: 0.5rem;
}

:root {
    /* Structure */

    --min-width: 360px;
    --max-width-form: 428px;
    --min-width-card: 328px;
    --max-width-card: 428px;
    --max-content-width: 428px;
    --inset-area: env(safe-area-inset-top, 0em);
    --viewport-height: calc(100dvh - var(--inset-area));
    --header-height: 5em;
    --brand-header-height: 2.875em;
    --tabs-height: 1.53125em;
    --categories-height: 2.1875em;
    --navbar-height: 5.5em;
    --z-content-overlays: 1;
    --z-select-menu: 2;
    --z-navigation: 3;
    --z-page-overlays: 4;
    --z-above-page-overlays: 5;
    --z-announcements: 6;
    --z-modals: 7;
    --z-above-modals: 8;
    --z-fullscreen: 9;
    --z-above-fullscreen: 10;
    --z-table-header: 3;
}
