.headerDisplay {
    display: flex;
}

.headerIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-right: 1em;
}

.headerDetails {
    display: flex;
    flex-direction: column;
}

.headerTitle {
    font-size: 1.125em;
}

.body {
    display: flex;
    flex-direction: column;

    height: 100%;
}

.form {
    display: flex;
    flex: 1;
    flex-direction: column;

    margin-bottom: 1.5em;

    overflow: auto;

    :global(.rs-checkbox):not(:last-child) {
        margin-bottom: 1.5em;
    }
}

.formRow {
    display: flex;
}

.formRowPadded {
    padding-top: 1em;

    .formRowIcon,
    .formRowField {
        padding-bottom: 1em;
    }
}

.formRowIcon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 3em;
}

.formRowField {
    display: flex;
    justify-content: space-between;

    width: 100%;
    min-height: 3.75em;

    margin-right: 1em;

    border-bottom: var(--border-width-base) solid var(--color-border-drawer);

    &.borderTop {
        border-top: var(--border-width-base) solid var(--color-border-drawer);
        border-bottom: none;
    }
}

.fieldDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fieldCount {
    margin-left: var(--font-space-character-width);

    color: var(--color-brand-red);
}

.fieldSubtitle {
    color: var(--color-text-subtext);
    font-size: 0.75em;
}

.fieldSwitch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.fieldSearch {
    display: flex;
    flex: 1;
    align-items: center;

    margin-right: 2.75em;
    margin-bottom: 1em;
    padding: 0.75em;

    border: var(--border-width-base) solid var(--color-input-border);
    border-radius: var(--border-radius-field);

    > svg {
        margin-right: 0.5em;

        color: var(--color-input-placeholder);
        font-size: 0.875em;
    }

    input {
        width: 100%;

        border: none;

        outline: none;

        box-shadow: none;

        &::placeholder {
            color: var(--color-input-placeholder);

            opacity: 1;
        }
    }
}

.buttons {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;

    padding: 1em;
    padding-bottom: 1.5em;

    border-top: var(--border-width-base) solid var(--color-border-drawer);

    button:not(:last-child) {
        margin-right: 1em;
    }
}

.drawerButtonPrimary {
    width: 12.5em;

    margin-bottom: 0;
}

.drawerButtonSecondary {
    --color-button-text: var(--color-brand-red);
    width: 8em;
}
