.switch {
    --switch-width: 3.25em;
    --thumb-size: 1.75em;
    --thumb-padding: 2px;
    position: relative;

    width: 3.25em;
    height: calc(var(--thumb-size) + calc(2 * var(--thumb-padding)));

    padding: 0;

    background-color: var(--color-switch-background-dark);

    border-radius: var(--thumb-size);

    &[data-state="checked"] {
        background-color: var(--color-switch-background-checked);
    }
}

.switchThumb {
    display: block;

    width: var(--thumb-size);
    height: var(--thumb-size);

    background-color: var(--color-switch-foreground);

    border-radius: var(--thumb-size);

    transform: translateX(var(--thumb-padding));

    transition: transform 100ms;

    will-change: transform;

    &[data-state="checked"] {
        transform: translateX(calc(calc(var(--switch-width) - var(--thumb-size)) - var(--thumb-padding)));
    }
}
