.container {
    position: fixed;
    bottom: calc(var(--navbar-height));
    z-index: var(--z-announcements);

    display: flex;
    justify-content: center;

    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;

    max-width: var(--max-width-card);

    margin: 0.75em;
    padding: 1em;

    color: var(--color-white);

    background-color: var(--color-grey-900);

    border-radius: var(--border-radius-card);

    box-shadow: var(--shadow-card);
}

.body {
    display: flex;
    justify-content: space-between;
}

.details {
    display: flex;
    flex-direction: column;

    margin-right: 1em;
}

.title {
    flex: 1;

    margin-bottom: 0.75em;

    font-weight: var(--font-base-weight-bold);
}

.close {
    display: flex;
    justify-content: center;

    button {
        margin: 0;
    }
}

.action {
    button {
        margin: 0;
    }
}
