.modal {
    :global(.rs-modal-content) {
        padding: 0;
    }

    &.nonPersistent :global(.rs-modal-header .rs-modal-header-close) {
        display: none;
    }
}

.header:global(.rs-modal-header) {
    position: relative;

    width: 100%;
    height: 18.75dvh;
    min-height: 9.375em;
    max-height: 12.5em;

    margin: 0;
    padding: 0;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    border-top-left-radius: var(--border-radius-modal);
    border-top-right-radius: var(--border-radius-modal);

    :global(.rs-modal-header-close) {
        position: absolute;
        top: 0.5em;
        right: 0.5em;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 2em;
        height: 2em;

        padding: 0;

        font-size: 1em;

        background-color: var(--color-overlay-light);

        border-radius: 50%;

        &:hover,
        &:focus-visible,
        &:active {
            background-color: var(--color-overlay);
        }

        svg {
            color: var(--color-white);
        }
    }
}

.modal.nonPersistent :global(.rs-modal-header .rs-modal-header-close) {
    display: none;
}

.splash {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 1em;

    background-color: var(--color-brand-red);

    border-radius: var(--border-radius-modal);

    :global(.rs-modal-content) {
        background-color: inherit;

        box-shadow: none;
    }

    .modalButtons {
        padding: 0;
    }
}

.splashBody {
    flex: 1;
}

.splashHeading {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;

    margin-bottom: 1em;

    color: var(--color-white);

    .splashSubtitle {
        margin-bottom: 0.125em;
    }

    .splashTitle {
        font-weight: var(--font-base-weight-bold);
        font-size: 2em;
    }
}

.splashImage {
    display: flex;
    flex: 1;
    justify-content: center;

    margin-bottom: 1.25em;
}

.body {
    padding: 1em;
}

.contentHeading {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 0.125em;
    margin-bottom: 0.875em;
}

.heading {
    font-weight: var(--font-base-weight-bold);
    font-size: 0.875em;
}

.headingFancy {
    color: var(--color-brand-red);
    font-weight: var(--font-base-weight-bold);
    font-size: 1.875em;
    text-shadow: 0 3px 1px hsla(0, 0%, 0%, 0.16);
}

.details {
    font-size: 0.875em;

    table {
        td {
            vertical-align: top;
        }

        td:not(:last-of-type) {
            padding-right: 1em;
        }
    }

    ol {
        margin-bottom: 0;
        padding-left: 1em;

        li:not(:last-child) {
            padding-bottom: 0.25em;
        }
    }

    em {
        color: var(--color-text-subtext);
    }
}

.modalButtons {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;

    padding: 1.25em;
    padding-top: 0;

    button {
        margin-bottom: 0;
    }
}

.smallButton {
    margin-top: 0.5em;

    font-size: 0.875em;
}
