.intercom-namespace > div > .intercom-messenger-frame {
    height: calc(100dvh);

    background-color: var(--color-white);

    /* Inset area env variable is set to 0 by intercom, so we're grabbing it from the store on body */

    --inset-area: var(--inset-area-saved);

    iframe {
        height: calc(100dvh - 1em - var(--inset-area));

        padding-top: var(--inset-area);
        padding-bottom: 1em;
    }
}
