.button {
    position: relative;

    display: inline-block;

    width: 100%;

    margin-bottom: 0.875em;
    padding: 0.75em;

    white-space: nowrap;
    text-align: center;
    text-decoration: none;

    border: 0;
    border-radius: var(--border-radius-button);

    outline: none;

    box-shadow: var(--shadow-button);

    cursor: pointer;

    &:hover,
    &:focus-visible,
    &:active,
    &.active {
        text-decoration: none;

        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    svg {
        margin-right: 0.5em;
    }
}

.waiting {
    box-shadow: none;

    cursor: wait;

    &:disabled {
        cursor: wait;
    }
}

.text {
    width: inherit;

    margin: 0;
    padding: 0;

    color: var(--color-button-text);
    text-decoration: underline;

    background: transparent;

    box-shadow: none;

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-text-subtext);
    }

    &:disabled {
        color: var(--color-grey-300);
    }
}

.textMuted {
    width: inherit;

    margin: 0;
    padding: 0;

    color: var(--color-button-text-muted);
    text-decoration: underline;

    background: transparent;

    box-shadow: none;

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-text-base);
    }

    &:disabled {
        color: var(--color-grey-300);
    }
}

.wrapper {
    width: inherit;

    margin: 0;
    padding: 0;

    white-space: inherit;
    text-align: inherit;

    background: transparent;

    border: none;
    border-radius: 0;

    box-shadow: none;

    svg {
        margin-right: inherit;
    }
}

.primary {
    color: var(--color-button-primary-text);

    background: var(--color-button-primary-background);

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-primary-hover-text);

        background: var(--color-button-primary-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-primary-disabled-text);

        background: var(--color-button-primary-disabled-background);
    }
}

.secondary {
    color: var(--color-button-secondary-text);

    background: var(--color-button-secondary-background);

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-secondary-hover-text);

        background: var(--color-button-secondary-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-secondary-disabled-text);

        background: var(--color-button-secondary-disabled-background);
    }
}

.secondaryOutline {
    color: var(--color-button-secondary-outline-text);

    background: var(--color-button-secondary-outline-background);

    border: var(--border-width-base) solid var(--color-button-secondary-outline-color);

    box-shadow: none;

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-secondary-outline-hover-text);

        background: var(--color-button-secondary-outline-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-secondary-outline-disabled-text);

        background: var(--color-button-secondary-outline-disabled-background);

        border: var(--border-width-base) solid var(--color-button-secondary-outline-disabled-color);
    }
}

.secondaryWhite {
    color: var(--color-button-secondary-white-text);

    background: var(--color-button-secondary-white-background);

    border: var(--border-width-base) solid var(--color-button-secondary-white-color);

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-secondary-white-hover-text);

        background: var(--color-button-secondary-white-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-secondary-white-disabled-text);

        background: var(--color-button-secondary-white-disabled-background);

        border: var(--border-width-base) solid var(--color-button-secondary-white-disabled-color);
    }
}

.secondaryGrey {
    color: var(--color-button-secondary-grey-text);

    background: var(--color-button-secondary-grey-background);

    border: var(--border-width-base) solid var(--color-button-secondary-grey-color);

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-secondary-grey-hover-text);

        background: var(--color-button-secondary-grey-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-secondary-grey-disabled-text);

        background: var(--color-button-secondary-grey-disabled-background);

        border: var(--border-width-base) solid var(--color-button-secondary-grey-disabled-color);
    }
}

.secondaryDarkGrey {
    color: var(--color-button-secondary-dark-grey-text);

    background: var(--color-button-secondary-dark-grey-background);

    border: var(--border-width-base) solid var(--color-button-secondary-dark-grey-color);

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-secondary-dark-grey-hover-text);

        background: var(--color-button-secondary-dark-grey-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-secondary-dark-grey-disabled-text);

        background: var(--color-button-secondary-dark-grey-disabled-background);

        border: var(--border-width-base) solid var(--color-button-secondary-dark-grey-disabled-color);
    }
}

.gold {
    color: var(--color-button-gold-text);

    background: var(--color-button-gold-background);

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-gold-hover-text);

        background: var(--color-button-gold-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-gold-disabled-text);

        background: var(--color-button-gold-disabled-background);
    }
}

.black {
    color: var(--color-button-black-text);

    background: var(--color-button-black-background);

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-black-hover-text);

        background: var(--color-button-black-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-black-disabled-text);

        background: var(--color-button-black-disabled-background);
    }
}

.success {
    color: var(--color-button-success-text);

    background: var(--color-button-success-background);

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-success-hover-text);

        background: var(--color-button-success-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-success-disabled-text);

        background: var(--color-button-success-disabled-background);
    }
}

.danger {
    color: var(--color-button-danger-text);

    background: var(--color-button-danger-background);

    border: var(--border-width-base) solid var(--color-button-danger-color);

    box-shadow: none;

    &:hover,
    &:focus-visible,
    &:active {
        color: var(--color-button-danger-hover-text);

        background: var(--color-button-danger-hover-background);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        color: var(--color-button-danger-disabled-text);

        background: var(--color-button-danger-disabled-background);

        border: var(--border-width-base) solid var(--color-button-danger-disabled-color);
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2em;
    height: 2em;

    margin: 0;
    padding: 0;

    background-color: var(--color-button-header-background);

    border-radius: 50%;

    box-shadow: var(--shadow-button);

    &:hover,
    &:focus-visible,
    &:active {
        background-color: var(--color-button-header-hover-background);

        box-shadow: var(--shadow-button-depressed);

        svg {
            color: var(--color-button-header-hover-text);
        }
    }

    svg {
        margin: 0;

        color: var(--color-button-header-text);
    }
}

.close {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2em;
    height: 2em;

    padding: 0;

    background-color: var(--color-button-close-background);

    border-radius: 50%;

    box-shadow: none;

    &:hover,
    &:focus-visible,
    &:active {
        background-color: var(--color-button-close-hover-background);

        svg {
            color: var(--color-button-close-hover-text);
        }
    }

    svg {
        margin: 0;

        color: var(--color-button-close-text);
    }
}

.closeInverse {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2em;
    height: 2em;

    padding: 0;

    background-color: var(--color-button-close-inverse-background);

    border-radius: 50%;

    box-shadow: none;

    &:hover,
    &:focus-visible,
    &:active {
        background-color: var(--color-button-close-inverse-hover-background);

        svg {
            color: var(--color-button-close-inverse-hover-text);
        }
    }

    svg {
        margin: 0;

        color: var(--color-button-close-inverse-text);
    }
}
