.container {
    display: flex;
    flex-direction: column;

    width: 100%;

    &:hover,
    & > button:focus-visible,
    &.open {
        .headerIndicator {
            color: var(--color-text-base);
        }
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-height: 3.75em;

    margin-right: 0.75em;
    padding: 0.5em 0;
}

.headerIndicator {
    color: var(--color-accordion-indicator);
}

.body {
    display: flex;
    flex-direction: column;

    margin-bottom: 1em;

    white-space: pre-line;

    &.bodyCentered {
        align-items: center;
    }
}
