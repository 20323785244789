@import "./reboot";
@import "./variables";
@import "./colors";
@import "./borders";
@import "./typography";
@import "./rsuite";
@import "./intercom";

html,
body {
    display: flex;
    flex-direction: column;

    min-width: var(--min-width);
    height: 100dvh;

    color: var(--color-text-base);
    font-weight: var(--font-base-weight);
    font-family: var(--font-base-family);
}

body {
    padding-top: var(--inset-area);

    overflow: hidden;

    background-color: var(--color-white);
}

main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    min-height: var(--viewport-height);

    padding: 1.5em 1em;
}

form {
    width: 100%;
    max-width: var(--max-width-form);
}

blockquote {
    position: relative;

    padding-left: 1em;

    border-left: var(--border-width-blockquote) solid var(--color-border-base);

    box-shadow: var(--shadow-panel);

    p {
        margin-bottom: 0;
    }
}

strong {
    font-weight: var(--font-base-weight-bold);
}

.tippy-box {
    white-space: pre-wrap !important;

    p {
        margin: 0;
    }
}
