.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image {
    margin-bottom: 1em;
}

.message {
    max-width: var(--max-width-card);

    text-align: center;
}

.primary {
    display: flex;
    flex-direction: column;
}

.primaryTitle {
    font-weight: var(--font-base-weight-bold);
    font-size: 1.5em;
}

.primarySubtitle {
    margin-bottom: 1em;
}

.primary,
.secondary {
    border-bottom: var(--border-width-base) solid var(--color-border-hr);
}

.secondary {
    text-align: left;

    li {
        margin-bottom: 0.5em;
    }
}
