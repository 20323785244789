:root {
    /* Library overrides */

    --rs-body: transparent;
    --rs-text-primary: var(--color-text-base);
    --rs-border-primary: var(--color-border-base);
    --rs-radio-checked-bg: var(--color-brand-red);
    --rs-checkbox-checked-bg: var(--color-brand-red);
    --rs-toggle-bg: var(--color-input-toggle-unchecked);
    --rs-toggle-checked-bg: var(--color-text-base);
    --rs-toggle-checked-hover-bg: black;
    --rs-rate-symbol-checked: var(--color-text-base);
    --rs-rate-symbol: var(--color-input-placeholder);
}

.rs-radio input:focus {
    outline: none;

    box-shadow: none;
}

.rs-radio [type="radio"]:focus ~ .rs-radio-inner::before {
    box-shadow: none;
}

// Modals
.rs-modal-backdrop {
    z-index: var(--z-modals);

    background-color: var(--color-modal-backdrop);
}

.rs-modal-wrapper {
    z-index: var(--z-above-modals);

    display: flex;
    align-items: center;
    justify-content: center;
}

.rs-modal {
    z-index: var(--z-above-modals);

    margin: 1em;
}

.rs-modal-content {
    padding: 1em;

    border-radius: var(--border-radius-modal);
}

.rs-modal-xs {
    width: 20em;
    min-width: 19em;
}

.rs-modal-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    margin-bottom: 1.5em;
    padding: 0 0.5em;

    .rs-modal-header-close {
        position: inherit;
        top: inherit;
        right: inherit;
        bottom: inherit;
        left: inherit;

        color: var(--color-text-subtext);
        font-size: 1.25em;
        line-height: 1.5;
    }
}

.rs-modal-body {
    margin-top: 0;
    margin-bottom: 1.5em;
    padding: 0;
}

// Drawers
.rs-drawer-backdrop {
    z-index: var(--z-modals);

    background-color: var(--color-modal-backdrop);
}

.rs-drawer-full.rs-drawer-bottom {
    height: 80dvh;
}

.rs-drawer-wrapper {
    z-index: var(--z-above-modals);

    display: flex;
}

.rs-drawer {
    z-index: var(--z-above-modals);
}

.rs-drawer-content {
    display: flex;
    flex-direction: column;
}

.rs-drawer-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    padding: 1em;

    &::before {
        display: none;
    }

    &::after {
        display: none;
    }

    .rs-drawer-header-close {
        position: inherit;
        top: inherit;
        right: inherit;
        bottom: inherit;
        left: inherit;

        line-height: 1.5;

        .rs-icon {
            color: var(--color-text-subtext);
            font-size: 1.5em;
        }
    }
}

.rs-drawer-header + .rs-drawer-body {
    height: 100%;
}

.rs-drawer-body {
    margin-top: 0;
    padding: 0;
}

// Ratings
.rs-rate {
    &:focus,
    &:hover {
        outline: none;
    }
}

.rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) {
    .rs-rate-character:hover,
    .rs-rate-character:focus-visible {
        transform: scale(1.1);
    }
}
