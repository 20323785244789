@import "@radix-ui/colors/gray.css";
@import "@radix-ui/colors/gray-alpha.css";
@import "@radix-ui/colors/gray-dark.css";
@import "@radix-ui/colors/gray-dark-alpha.css";

@import "@radix-ui/colors/tomato.css";
@import "@radix-ui/colors/tomato-alpha.css";
@import "@radix-ui/colors/tomato-dark.css";
@import "@radix-ui/colors/tomato-dark-alpha.css";

@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/red-alpha.css";
@import "@radix-ui/colors/red-dark.css";
@import "@radix-ui/colors/red-dark-alpha.css";

@import "@radix-ui/colors/pink.css";
@import "@radix-ui/colors/pink-alpha.css";
@import "@radix-ui/colors/pink-dark.css";
@import "@radix-ui/colors/pink-dark-alpha.css";

@import "@radix-ui/colors/cyan.css";
@import "@radix-ui/colors/cyan-alpha.css";
@import "@radix-ui/colors/cyan-dark.css";
@import "@radix-ui/colors/cyan-dark-alpha.css";

@import "@radix-ui/colors/indigo.css";
@import "@radix-ui/colors/indigo-alpha.css";
@import "@radix-ui/colors/indigo-dark.css";
@import "@radix-ui/colors/indigo-dark-alpha.css";

@import "@radix-ui/colors/violet.css";
@import "@radix-ui/colors/violet-alpha.css";
@import "@radix-ui/colors/violet-dark.css";
@import "@radix-ui/colors/violet-dark-alpha.css";

@import "@radix-ui/colors/grass.css";
@import "@radix-ui/colors/grass-alpha.css";
@import "@radix-ui/colors/grass-dark.css";
@import "@radix-ui/colors/grass-dark-alpha.css";

@import "@radix-ui/colors/amber.css";
@import "@radix-ui/colors/amber-alpha.css";
@import "@radix-ui/colors/amber-dark.css";
@import "@radix-ui/colors/amber-dark-alpha.css";

@import "@radix-ui/colors/orange.css";
@import "@radix-ui/colors/orange-alpha.css";
@import "@radix-ui/colors/orange-dark.css";
@import "@radix-ui/colors/orange-dark-alpha.css";

@import "@radix-ui/colors/black-alpha.css";

:root,
.light,
.light-theme {
    --white: var(--gray-2);
    --black: var(--gray-12);
}

.dark,
.dark-theme {
    --white: var(--gray-2);
    --black: var(--gray-12);
}
