.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    max-width: var(--max-width-card);
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
}

.hero {
    width: 100%;

    margin-top: 1.25em;
    margin-bottom: 0.5em;

    text-align: center;
}

.heroImage {
    margin-bottom: 0.5em;
}

.title {
    margin-bottom: 0.25em;

    color: var(--color-brand-red);
    font-weight: var(--font-base-weight-bold);
    font-size: 1.5em;
}

.subtitle {
    font-size: 0.875em;
}

.permissions {
    margin-bottom: 1em;

    border-bottom: var(--border-width-base) solid var(--color-border-hr);
}

.permission {
    & > div {
        /* Icon */

        & > div:first-of-type {
            align-items: flex-start;
            justify-content: flex-start;

            width: 2em;

            margin-top: 1.325em;
        }

        /* Description and Toggle */

        & > div:last-of-type {
            margin-right: 0;

            /* Description */

            & > div:first-of-type {
                margin-top: 1em;
                margin-right: 0.5em;

                /* Body */

                & > div:last-of-type {
                    color: var(--color-text-base);
                    font-size: 0.875em;
                }
            }

            /* Toggle */

            & > div:last-of-type {
                margin-right: 0;
            }
        }
    }

    .errorMessage {
        color: var(--color-brand-red);
    }
}
